.form-signin {
  margin-top: (@navbar-height*3);

  .form-control {
    margin-bottom: (@line-height-computed/2);
    text-align: center;

    &::placeholder {
      text-align: center;
    }
  }
}

.bg-gray-light {
  background-color: @gray-light;
}

.submenue,
.logo {
  margin-top: @line-height-computed;
  margin-bottom: @line-height-computed;
}

.text-bold {
  font-weight: bold;
}