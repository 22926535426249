//
// Thumbnails
// --------------------------------------------------


// Mixin and adjust the regular image class
.thumbnail {
  display: block;
  padding: @thumbnail-padding;
  margin-bottom: @line-height-computed;
  line-height: @line-height-base;
  background-color: @thumbnail-bg;
  border: 1px solid @thumbnail-border;
  border-radius: @thumbnail-border-radius;
  .transition(border .2s ease-in-out);

  > img,
  a > img {
    &:extend(.img-responsive);
    margin-left: auto;
    margin-right: auto;
  }

  // Add a hover state for linked versions only
  a&:hover,
  a&:focus,
  a&.active {
    border-color: @link-color;
  }

  // Image captions
  .caption {
    padding: @thumbnail-caption-padding;
    color: @thumbnail-caption-color;
  }
}


.product-list .thumbnail {
  padding: 0;

  #gradient .vertical(#fff, @thumbnail-border, 20%);

  > img,
  a > img {
    border: 1px solid @gray-dark;
  }

  .image {
    display: table;
    width: 100%;

    .inner {
      display: table-cell;
      vertical-align: middle;
      height: 237px;
      padding: 20px;
    }
  }

  .caption {
    padding: 0;
    overflow: hidden;
  }

  .btn-primary {
    &:hover {
      color: @btn-warning-color;
      background-color: @btn-warning-bg;
      border-color: @btn-warning-border;
    }
  }

  &:hover {
    .btn-primary {
      color: @btn-warning-color;
      background-color: @btn-warning-bg;
      border-color: @btn-warning-border;
    }
  }
}